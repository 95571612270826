@import "../variables.modules";

footer {
  color: $dark;
}

.dark {
  background-color: $dark;
  color: $white;
  transition: all 400ms;

  a, i, li {
    color: $white;
    transition: color 400ms;
  }

  a:visited {
    color: $white;
  }

  footer {
    color: $white;
  }
}

.light {
  background-color: $white;
  color: $dark;
  transition: all 400ms;
}

.light hr {
  background-color: lightgray;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dark hr {
  background-color: #555;
}

.light textarea,
.light input {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 1.5vh;
  resize: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid lightgray;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #fff;
  color: #333;
}

.light textarea:focus,
.light input:focus {
  border-color: cornflowerblue;
  box-shadow: 2px 2px 8px rgba(100, 149, 237, 0.5);
}

.dark textarea,
.dark input {
  resize: none;
  padding: 10px 15px;
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 1.5vh;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #555;
  box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  background-color: #444 !important;
  color: #fff;
}

.dark textarea:focus,
.dark input:focus {
  border-color: #ddd;
  box-shadow: 2px 2px 8px rgba(255, 255, 255, 0.5);
}





//.dark {
//  background-color: $dark;
//  color: $white;
//  transition: all 400ms;
//  a, i {
//    color: $white;
//    transition: color 400ms;
//  }
//  a:visited {
//    color: $white;
//  }
//  i:hover {
//    color: $purple;
//  }
//  footer {
//    color: $white;
//  }
//
//  nav {
//    background-color: $dark;
//    transition: $transition;
//  }
//
//  div > section > div:first-child {
//    background-color: #575e64;
//    i:first-child {
//      color: $red;
//    }
//    i:nth-child(2) {
//      color: $yellow;
//    }
//    i:nth-child(3) {
//      color: $green;
//    }
//  }
//
//
//  & > div div {
//    i {
//      color: white;
//    }
//  }
//}