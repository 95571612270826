@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;

  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;

  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}

h3 {
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;

  @media only screen and (min-width: 940px) {
    font-size: 1.5rem;
    text-align: left;
  }
}

h4 {
  font-size: 0.75rem;
  text-align: center;

  @media only screen and (min-width: 940px) {
    font-size: 1rem;
    text-align: left;
  }
}


.coolButton {
  display: inline-block;
  padding: 1em 2em;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 50px;
  background-color: cornflowerblue;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.coolButton:hover {
  background-color: rgb(255, 255, 255);
  color: cornflowerblue;
  box-shadow: 2px 2px 8px rgba(100, 149, 237, 0.5);
}

.coolButton:active {
  transform: translate(2px, 2px);
  box-shadow: none;
}